import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import AddShoppingCartRoundedIcon from "@material-ui/icons/AddShoppingCartRounded"
import SentimentDissatisfiedRoundedIcon from "@material-ui/icons/SentimentDissatisfiedRounded"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Helmet } from "react-helmet"
import CategorySlider from "../components/category-slider"
import Layout from "../components/layout"
import ProductCard from "../components/Product Card/ProductCard"
import Grey from "@material-ui/core/colors/grey"


const useStyles = makeStyles({
  HorizontalGrid:{
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    background: Grey[900],

    },
  card: {
    width: 300,
    height: 300,
    margin: "1rem",
  },
  gridContainer: {
    marginTop: "1rem",
    touchAction: "pan-y, pinch-zoom"
  },
  gridItem: {
    maxWidth: 345
  },
  gradientHeader: {
    background:
      "linear-gradient(-225deg, #2CD8D5 0%, #6B8DD6 48%, #8E37D7 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  ImageBackground: {
    background: 'linear-gradient(135deg, #A8A1AD 0%, #9B99AD 41.15%, #A7A2B2 78.65%, #BFBCC4 100%)'
  }
  // HorizontalGrid:{
  //   background: Grey[200]

  // }

})

// background-image: linear-gradient(-225deg, #2CD8D5 0%, #6B8DD6 48%, #8E37D7 100%);
const ShopPage = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    {
      allContentfulVendorProduct(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            slug
            mainImage {
              gatsbyImageData(
                width: 600
                height: 600
                breakpoints: 300
                cropFocus: CENTER
                formats: AUTO
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                resizingBehavior: SCALE
              )
            }
            images {
              gatsbyImageData(
                width: 600
                height: 600
                breakpoints: 300
                cropFocus: CENTER
                formats: AUTO
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                resizingBehavior: SCALE
              )
            }
            isVariation
            brand
            availableUnits
            Category
          }
        }
      }
    }
  `)
console.log(data)
  const productArray = data.allContentfulVendorProduct.edges

  return (
    <>
      <Helmet>
        <title>Shop | Bongs | Dab Rigs | Pipes | Smoking Accessories</title>
        <meta name="description" content="All Samurai Blaze Products, smoking accessories" />
      </Helmet>


      <Layout>
        <Typography variant='h1'>
          Shop
        </Typography>
        <div className={classes.HorizontalGrid}>

        <ProductCard ></ProductCard>
        </div>
        
      </Layout>
    </>
  )
}
export default ShopPage
