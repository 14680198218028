import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import Grow from "@material-ui/core/Grow"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import OutofStockButton from "../Buttons/outOfStockButtons"

const useStyles = makeStyles({
  Card: {
    minWidth: 300,
    minHeight: 300,
    margin: "1rem"
  },
  imageBox: {
    minHeight: 300,
    minWidth: 300,
    backgroundColor: "black"
  },
  image: {
    background:
      "linear-gradient(135deg, #A8A1AD 0%, #9B99AD 41.15%, #A7A2B2 78.65%, #BFBCC4 100%)"
  },
  overlay: {
    height: "max-content",
    width: "max-content",
    color: "white",
    transition: ".5s ease",
    Opacity: 0
  }
})

export default function ProductCard(props) {
  const data = useStaticQuery(graphql`
    {
      allContentfulVendorProduct(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            slug
            id
            mainImage {
              gatsbyImageData(
                width: 600
                height: 600
                breakpoints: 300
                cropFocus: CENTER
                formats: AUTO
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                resizingBehavior: SCALE
              )
            }
            images {
              gatsbyImageData(
                width: 600
                height: 600
                breakpoints: 300
                cropFocus: CENTER
                formats: AUTO
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                resizingBehavior: SCALE
              )
            }

            brand
            storePrice
            availableUnits
            Category
            title
            price
          }
        }
      }
    }
  `)

  //   console.log(data)

  const [favorite, isFavorite] = useState(false)
  const classes = useStyles()
  // console.log(favorite);
  const dataArray = data.allContentfulVendorProduct.edges

  return (
    <>
      {dataArray.map((dataArray, index) => {
        const slug = `/shop/${dataArray.node.slug}`
        const image = getImage(dataArray.node.mainImage)
        const brand = dataArray.node.brand
        const availableUnits = dataArray.node.availableUnits
        const Category = dataArray.node.Category
        const title = dataArray.node.title
        const price = dataArray.node.price
        const id = dataArray.node.id
        const url = slug
        const mainImage = image

        return (
          <Grow in={true} timeout={400}>
            <Card key={index.card} raised={false} className={classes.Card}>
              <div className={classes.imageBox} key={index.image}>
                <Link to={slug}>
                  <GatsbyImage
                    className={classes.image}
                    image={image}
                    alt={title}
                  />
                </Link>
              </div>

              <Typography key={index.title} align="center" variant="h5">
                {title}
              </Typography>
              <Typography align="center" variant="body1">
                {brand}
              </Typography>
              <Typography align="center" variant="body1">
                ${price} USD
              </Typography>
              <div>
                {availableUnits >= 1 ? (
                  <div>
                    <Link to={slug}>

                      <Button color='secondary' variant='contained' fullWidth={true}>
                        Learn More
                      </Button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <OutofStockButton />
                  </div>
                )}
              </div>
            </Card>
          </Grow>
        )
      })}
    </>
  )
}


