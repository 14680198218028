import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const useStyles = makeStyles({
  card: {
    width: 200,
    height: 200,


  }
})

const CategorySlider = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    {
      allContentfulCategoryPage(filter: { node_locale: { eq: "en-US" } }){
        edges {
          node {
            slug
          }
        }
      }
    }
  `)
  const array = data.allContentfulCategoryPage.edges


  //   console.log(array)
  return (
    <>
      {array.map((array) => {
        console.log(array.node.slug)
        const link = `/collections/${array.node.slug}`


        return (

          <div key={array.node}>


            <Link to={link}>
              <Typography align="center" variant="h5">

                {array.node.slug}
              </Typography>
            </Link>

          </div>
        )
      })}
    </>

  )
}


export default CategorySlider