import Button from "@material-ui/core/Button"
import SentimentDissatisfiedRoundedIcon from "@material-ui/icons/SentimentDissatisfiedRounded"
import React from "react"


const OutofStockButton = (props) => {
    return (
        <Button
            disabled
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<SentimentDissatisfiedRoundedIcon />}
            key={props.id}
            fullWidth={true}
        >
            Not Available
        </Button>
    )
}


export default OutofStockButton